
import { defineComponent, onMounted, ref } from "vue";
import PlanoColumn from "@/util/table-columns/PlanoColumn";
import PlanoForm from "@/components/admin/planos/PlanoForm.vue";
import useList from "@/modules/useList";
import store from "@/store";
import DivisaoService from "@/services/auth/DivisaoService";
import Curso from "@/interfaces/Curso";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import CursoService from "@/services/admin/CursoService";

export default defineComponent({
  components: { PlanoForm },
  setup() {
    const $q = useQuasar();
    const cursos = ref<Curso[]>([]);
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      confirmDelete,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/planos/" + store.getters.divisaoId
        : "planos",
      PlanoColumn
    );

    const getCursos = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
            if (!cursos.value.length) {
              showNotify({
                type: "warning",
                message: "Não há cursos para essa divisão",
              });
            }
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        CursoService.list()
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getCursos();
    });

    return {
      rows,
      cursos,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
      confirmDelete,
    };
  },
});
