import Plano from "@/interfaces/Plano";

const PlanoColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    style: "width: 100px",
    sortable: false,
  },
  {
    name: "descricao",
    align: "left",
    label: "Nome",
    field: "descricao",
    sortable: true,
  },
  {
    name: "resumo",
    align: "left",
    label: "Resumo",
    field: "resumo",
    sortable: true,
  },
  {
    name: "valor",
    align: "center",
    label: "Valor",
    field: "valor",
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: Plano) => row.created_at,
    sortable: true,
  },
];

export default PlanoColumn;
