
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import Curso from "@/interfaces/Curso";
import Plano from "@/interfaces/Plano";
import store from "@/store";
import useForm from "@/modules/useForm";

interface Form {
  descricao: string;
  valor: number | null;
  resumo: string | null;
  cursos: string[];
  divisao_id: string;
}

const baseForm: Form = {
  descricao: "",
  valor: null,
  resumo: "",
  cursos: [],
  divisao_id: store.getters.divisaoId,
};

export default defineComponent({
  components: { FormLoading },
  props: { cursos: { type: Object, required: true } },
  setup(props, { emit }) {
    const { show, form, row, loading, submit, afterSubmit } = useForm(
      "planos",
      "plano",
      "o",
      baseForm,
      emit,
      "planos"
    );

    const open = (data: Plano | null) => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            descricao: row.value.descricao,
            valor: row.value.valor,
            resumo: row.value.resumo,
            cursos: row.value.cursos.map((c: Curso) => c.id),
            divisao_id: store.getters.divisaoId,
          };
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
        }
      }
      show.value = true;
    };

    return {
      show,
      open,
      form,
      row,
      requiredField,
      loading,
      submit,
      afterSubmit,
    };
  },
});
